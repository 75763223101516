import scanInterceptorCreator from '@/components/scan-interceptor/scan-interceptor';
import errorOverlayCreator from '@/components/error-overlay/error-overlay';
import scanCode from '@/utils/scan-code';
import { createControllerList } from '@/utils/create-controllers';

export function init() {
    const [wrongCodeErrorOverlay] = createControllerList(document, '#wrong-code', errorOverlayCreator);
    const [scanInterceptor] = createControllerList(document, '[data-scan-interceptor]', scanInterceptorCreator);
    scanInterceptor.addEventListener('scan', (e) => {
        const code = e.detail.code;
        scanCode(code)
            .then(({ redirect }) => {
                redirect();
            })
            .catch(() => {
                scanInterceptor.reset();
                wrongCodeErrorOverlay.show();
            });
    });
}
